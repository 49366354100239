<template>
  <div>
    <b-card
      title="รายการฝากเงิน"
      no-body
    >
      <b-card-body>
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="บัญชี"
                label-for="mc-first-name"
              >
                <v-select
                  v-model="tableQuery.bankaccount"
                  label="title"
                  :options="bankaccounts"
                >
                  <template #option="data">
                    <span>{{ data.acc_type.replace('IN','ฝาก').replace('OUT','ถอน') }} {{ bankMaps[data.bank_code] }} {{ data.acc_no }} {{ data.acc_name }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="วันที่รายการ"
                label-for="mc-country"
              >
                <b-row>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.startdate"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.enddate"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="fetchSystemStatement"
              >
                ค้นหา
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="clearSearch"
              >
                เคลียร์
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!-- </div> -->
      </b-card-body>
    </b-card>
    <b-row>
      <b-col
        v-if="statementItems.length > 0"
        xl="12"
        md="12"
      >
        <b-card
          no-body
          class="card-statistics"
        >
          <b-card-header>
            <b-card-title>สถิติรายการ</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              Updated 1 mins ago
            </b-card-text>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                v-for="item in stats"
                :key="item.icon"
                xl="3"
                sm="6"
                :class="item.customClass"
              >
                <b-media no-body>
                  <b-media-aside

                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      :variant="item.color"
                    >
                      <feather-icon
                        size="24"
                        :icon="item.icon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ item.title }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ item.subtitle }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card
          title="รายการเดินบัญชี *เวอร์ชั่นทดสอบ*"
        >
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 mb-1"
                @click="saveToExcel()"
              >
                ดึง Excel
              </b-button>
            </b-col>
          </b-row>
          <b-table
            ref="statementTable"
            striped
            hover
            responsive
            class="position-relative"
            :busy.sync="isBusy"
            :per-page="2000"
            :current-page="1"
            :items="statementItems"
            :fields="statementsTableFields"
          >
            <template #cell(tx_datetime)="data">
              <div>
                {{ `${data.item.tx_date} ${data.item.tx_time}` }}
              </div>
            </template>
            <template #cell(amount)="data">
              <div :style="`text-align: right; ${(data.item.tx_type === 'OUT' ? 'color: red;':'')}`">
                {{ `${(data.item.tx_type === 'OUT' ? '-':'+')}${numberWithCommas(data.item.amount)}` }}
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BButton, BCardBody, BRow, BCol, BForm, BCardHeader, BCardTitle, BCardText, BMedia, BAvatar, BMediaAside, BMediaBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  components: {
    BCard,
    BTable,
    // BBadge,
    BFormGroup,
    // BFormSelect,
    // BPagination,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    vSelect,
    flatPickr,
    BCardHeader,
    BCardTitle,
    BCardText,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      bankaccounts: [],
      statementItems: [],
      isBusy: false,
      refRecheck: null,
      totalRows: 1,
      tableQuery: {
        bankaccount: null,
        startdate: moment().format('YYYY-MM-DD 00:00:00'),
        enddate: moment().add(1, 'days').format('YYYY-MM-DD 00:00:00'),
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      statementsTableFields: [
        { key: 'tx_datetime', label: 'วันที่', formatter: val => moment(val).format('DD/MM HH:mm') },
        { key: 'tx_detail', label: 'รายการ' },
        { key: 'amount', label: 'จำนวนเงิน', formatter: val => this.numberWithCommas(val) },
        { key: 'match_user', label: 'รหัสจับคู่' },
        { key: 'username', label: 'ยูสเซอร์', formatter: (val, k, item) => `${item.phoneno}\t\n${item.username}` },
        { key: 'fullname', label: 'ชื่อลูกค้า' },
        // { key: 'tx_acc', label: 'บัญชีฝาก' },
      ],
      bankMaps: {
        '004': 'kbank', '014': 'scb', '006': 'krungthai', TMW: 'TrueWallet',
      },
      stats: [
        {
          color: 'light-primary',
          customClass: 'mb-2 mb-xl-0',
          icon: 'TrendingUpIcon',
          subtitle: 'จำนวนรายการเข้า',
          title: '',
        },
        {
          color: 'light-success',
          customClass: '',
          icon: 'DollarSignIcon',
          subtitle: 'จำนวนเงินเข้า',
          title: '',
        },
        {
          color: 'light-danger',
          customClass: 'mb-2 mb-xl-0',
          icon: 'TrendingUpIcon',
          subtitle: 'จำนวนรายการออก',
          title: '',
        },
        {
          color: 'light-danger',
          customClass: '',
          icon: 'DollarSignIcon',
          subtitle: 'จำนวนเงินออก',
          title: '',
        },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.fetchBankAccounts()
  },
  methods: {
    clearSearch() {
      this.tableQuery = {
        bankaccount: null,
        startdate: moment().format('YYYY-MM-DD 00:00:00'),
        enddate: moment().add(1, 'days').format('YYYY-MM-DD 00:00:00'),
      }
      this.statementItems = []
    },
    async matchUser(bankTransferData) {
      // console.log(bankTransferData)
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        if (j === 'tx_datetime') {
          return moment(v[j]).format('DD/MM HH:mm')
        }
        return v[j]
      }))
    },
    saveToExcel() {
      import('@/libs/Export2Excel').then(excel => {
        const headerTitle = ['วันที่เวลา', 'รายละเอียด', 'จำนวน', 'ยูสเซอร์', 'เบอร์โทร', 'ชื่อนามสกุล']
        const headerVal = ['tx_datetime', 'tx_detail', 'amount', 'username', 'phoneno', 'fullname']
        const data = this.formatJson(headerVal, this.statementItems)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: `${this.tableQuery.bankaccount.title}-${this.tableQuery.startdate}`,
          autoWidth: true,
          bookType: 'xlsx',
        })
      })
    },
    async fetchSystemStatement() {
      if (this.tableQuery.bankaccount === null)
        return
      this.isBusy = true
      const {
        data: {
          statements,
          countIn: { countIn },
          sumIn: { sumIn },
          countOut: { countOut },
          sumOut: { sumOut },
        },
      } = await this.$http.post('botauto-bankstatements', this.tableQuery)
      this.statementItems = statements
      this.isBusy = false
      this.stats[0].title = `${countIn || 0}`
      this.stats[1].title = `${this.numberWithCommas(sumIn || 0)}`
      this.stats[2].title = `${countOut || 0}`
      this.stats[3].title = `${this.numberWithCommas(sumOut || 0)}`
      // .filter(i => (i.processed === 'Y' && i.matched === 'Y'))
    },
    async fetchBankAccounts() {
      const { data: _bankaccounts } = await this.$http.get('bankaccounts')
      this.bankaccounts = _bankaccounts.bankaccounts.data.map(i => ({
        ...i, title: `${i.acc_type.replace('IN', 'ฝาก').replace('OUT', 'ถอน')} ${this.bankMaps[i.bank_code]} ${i.acc_no} ${i.acc_name}`, acc_type: i.acc_type, queue_name: i.bank_alias,
      }))
      // this.bankaccounts.push({
      //   title: 'ทรูมันนี่', acc_no: '', bank_id: 'DEPOSIT-TMW', acc_name: 'ฝากทรูมันนี่',
      // })
      // this.bankaccounts.push({
      //   title: 'ทรูมันนี่', acc_no: '', bank_id: 'WITHDRAW-TMW', acc_name: 'ถอนทรูมันนี่', acc_type: 'OUT', queue_name: 'TMW',
      // })
      // this.bankaccounts.push({
      //   title: 'รายการฝากมือ', acc_no: '', bank_id: 'deposit-manual', acc_name: 'ฝากมือ',
      // })
      // this.bankaccounts.push({
      //   title: 'รายการฝากมือ', acc_no: '', bank_id: 'withdraw-manual', acc_name: 'ถอนมือ', acc_type: 'OUT', queue_name: 'M',
      // })
      // this.bankaccounts.push({
      //   title: 'รายการฝากมือ', acc_no: '', bank_id: 'deduction', acc_name: 'ตัดเครดิต/ยึดเครดิต', acc_type: 'DEDUCTION',
      // })
      // this.bankaccounts
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
